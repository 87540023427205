import React, { useState } from "react";

import LazyShow from "../components/LazyShow";
import Hero from "../components/Hero";
import About from "../components/About";
import LatestNews from "../components/LatestNews";
import Network from "../components/Network";
import Organization from "../components/Organization";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import MobileHeader from "../components/MobileHeader";
import ShortNews from "../components/ShortNews";
function Home() {
  return (
    <div>
      {/* <MobileHeader/> */}
      <Header />
      <MobileHeader home={true}/>
      <LazyShow>
        <Hero/>
      </LazyShow>
      <LazyShow>
        <ShortNews/>
      </LazyShow>
      <LazyShow>
        <>
          <About />
        </>
      </LazyShow>
      <LazyShow>
        <>
          <LatestNews/>
        </>
      </LazyShow>
      {/* <LazyShow>
        <div className="hidden md:block">
          <Network/>
        </div>
      </LazyShow> */}
      <LazyShow>
        <>
          <Organization />
        </>
      </LazyShow>
      <LazyShow>
        <>
          <MobileMenu />
        </>
      </LazyShow>
      <LazyShow>
        <>
          <Footer />
        </>
      </LazyShow>
    </div>
  );
}

export default Home;
