import React, { ReactNode, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import { useNavigate } from 'react-router-dom';
import {Block, Inline, BLOCKS, MARKS } from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import { Link } from 'react-router-dom';

function ShortNews() {

    const navigate = useNavigate();
    const [ title, setTitle ] = useState('') 
    const [ subtitle, setSubtitle] = useState()
    const [ textColor, setTextColor ] = useState('')
    const [ name, setName ] = useState('')
    const [ buttonLink, setButtonLink ] = useState('')
    const [ buttonLabel, setButtonLabel ] = useState('')
    const [ buttonColor, setButtonColor ] = useState('')
    const [ buttonLabelColor, setButtonLabelColor ] = useState('')
    const [ backgroundColor, setBackgroundColor ] = useState('')
    const [ announcement, setAnnouncement ] = useState()
    const [ emptyContentful, setEmptyContentful ] = useState(true)
    const [ buttonRollColor, setButtonRollColor] = useState('')
    const [ isButtonHovered, setIsButtonHovered ] = useState(false)
    const CONTENTFUL_SPACE_ID = "qitjy5hyl5up"
    const CONTENTFUL_ACCESS_TOKEN = "TKo1zCQL_v1NQmGcRQXQVqayvDSqukrdCeRlbpEedyM"  
    const ENTRY_ID = "1MlFCiSkM7EtUNA3GnlnYs"
    // const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=news`;
    // const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/entries?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=${CONTENT_TYPE}&fields.name[match]=${CONTENT_NAME}`;
    const contentfulEndpointNews = `https://cdn.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/entries/${ENTRY_ID}?access_token=${CONTENTFUL_ACCESS_TOKEN}`;
    const options = {
        renderMark: {
        //   [MARKS.BOLD]: (text:ReactNode) => <div className="font-bold">{text}</div>,
        },
        preserveWhitespace: true,
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node:Block|Inline, children:ReactNode) => <p className={`font-normal text-[] text-[${textColor}]`}>{children}</p>,
        },
      };
    
    useEffect(() => {
        (async () => {
            try {
              const res = await fetch(contentfulEndpointNews);
              const data = await res.json();              
              const { title='', subtitle={}, textColor='', name='', buttonLink='', buttonLabel='', buttonColor='', buttonRollColor='', buttonLabelColor='', backgroundColor='', announcement={} } = data.fields
              setTitle(title)
              setSubtitle(subtitle)
              setTextColor(textColor)
              setName(name)
              setButtonLink(buttonLink)
              setButtonColor(buttonColor)
              setButtonLabel(buttonLabel)
              setButtonLabel(buttonLabel)
              setButtonRollColor(buttonRollColor)
              setButtonLabelColor(buttonLabelColor)
              setBackgroundColor(backgroundColor)
              setAnnouncement(announcement)
              setEmptyContentful(false)
              console.log('button roll color', buttonColor, buttonRollColor)
            } catch (error) {
              setEmptyContentful(true)
              console.error(error);
            }
          })();
    }, []);

    return (
        <>
        {emptyContentful ?        
            <></>
            :
            <div className={`w-full bg-[${backgroundColor}] py-8 px-12`} style={{backgroundColor:backgroundColor}}>
                <div className='flex flex-row items-center'>
                    <div className='w-[50%]'>
                        <div className={`font-bold text-[32px] text-[${textColor}]`}>{title}</div>
                        <div>
                            {subtitle &&
                                documentToReactComponents(subtitle, options)
                            }
                        </div>
                        <div className='mt-1'>
                            {announcement &&
                                documentToReactComponents(announcement, options)
                            }
                        </div>
                    </div>
                    <div className='flex justify-center items-center w-[40%]'>
                        <Link
                            onMouseOver={()=>{setIsButtonHovered(true)}} 
                            onMouseLeave={()=>{setIsButtonHovered(false)}}
                            to={buttonLink}
                            className='bg-[#00a695] hover:bg-[#0c2957] inline-block text-white bg-green px-12 py-6 relative text-sm font-semibold cursor-pointer transition hover:transition-all btnDemo'
                            style={{backgroundColor: isButtonHovered ? buttonRollColor : buttonColor, color:buttonLabelColor}}
                        >
                            {buttonLabel}
                        </Link>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default ShortNews